import GeolocationController from "./geolocation_controller"

export default class extends GeolocationController {
  connect() {
  }

  fetch(_event) {
    this.getCurrentPosition()
        .then(({latitude, longitude}) => {
          this.search(latitude, longitude)
        })
  }

}
