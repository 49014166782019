import GeolocationController from "./geolocation_controller"
import 'leaflet'

export default class extends GeolocationController {

  connect() {
    this.getCurrentPosition()
        .then(({latitude, longitude}) => {
          this.loadMap(latitude, longitude)
        })
  }

  loadMap(latitude, longitude) {
    console.log(`Loading map at ${latitude}, ${longitude}`)
    this.map = L.map(this.element).setView([latitude, longitude], 13);

    this.addLayer()

    const url = this.data.get('locations-url')
    fetch(`${url}?lat=${latitude}&lng=${longitude}`)
        .then((r) => r.json())
        .then((json) => {
          console.log(json)
          json.forEach(({ latitude, longitude, title }) => {
            this.addLocationMarker(latitude, longitude)
          })
        })
  }

  addLocationMarker(latitude, longitude) {
    L.marker(
      [latitude, longitude],
      {
        icon: new L.Icon({
          iconUrl: this.data.get('marker-url'),
          retinaUrl: this.data.get('marker-2x-url'),
          shadowUrl: this.data.get('marker-shadow-url'),
          iconSize: [24, 24],
          iconAnchor: [12, 24],
          popupAnchor: [1, -34],
          shadowSize: [41, 41]
        })
      }
    ).addTo(this.map);
  }

  addLayer() {
    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
      maxZoom: 18,
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a> ' +
        '<a target="_blank" href="https://icons8.com/icons/set/marker">Marker icon</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>',
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1
    }).addTo(this.map);
  }

}
