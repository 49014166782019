// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['container']

  autoload(event) {
    console.log('autoload')
    const { latitude, longitude } = event.detail
    const url = this.data.get('url')

    this.load(`${url}?lat=${latitude}&lng=${longitude}`)
  }

  change(event) {
    const option = event.target.selectedOptions[0]
    const url = option.dataset.contentUrl

    this.load(url)
  }

  load(url) {
    fetch(url)
        .then((r) => r.text())
        .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html);

          if (this.containerTarget.firstElementChild) {
            this.containerTarget.removeChild(this.containerTarget.firstElementChild)
          }

          this.containerTarget.appendChild(fragment)
        });
  }
}
