import { Controller } from "stimulus"

export default class GeolocationController extends Controller {
  getCurrentPosition() {
    if (navigator.geolocation) {
      return new Promise(
        (resolve, reject) => {
          navigator.geolocation.getCurrentPosition(({ coords }) => {
            const event = document.createEvent('CustomEvent')
            event.initCustomEvent('location', true, true, coords)
            this.element.dispatchEvent(event)
            resolve(coords)
          }, reject)
        }
      )
    } else {
      return new Promise(
        resolve => resolve({})
      )
    }
  }

  search(latitude, longitude) {
    const url = this.data.get('url')
    fetch(`${url}?lat=${latitude}&lng=${longitude}`)
      .then((r) => r.text())
      .then((html) => {
        this.element.outerHTML = html;
      })
  }
}
